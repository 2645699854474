import { Locales } from './locales'

import en from './en-gb/index.json'
import fr from './fr-fr/index.json'

export const messages = {
  [Locales.EN]: en,
  [Locales.FR]: fr
}

let navigatorLanguage = navigator.language

if (navigatorLanguage.indexOf('-') > -1) {
  navigatorLanguage = navigatorLanguage.substring(
    0, navigatorLanguage.indexOf('-'))
}

let defaultLanguage = 'en'
if (Object.keys(messages).indexOf(navigatorLanguage) > -1) {
  defaultLanguage = navigatorLanguage
}

export const defaultLocale = defaultLanguage

<template>
  <nav v-if="isReady">
    <ul>
      <li v-for="item in activeButtons" v-bind:key="item.name"
          class="nav-link-wrapper">
        <button v-on:click="navigate(item.path)"
          :class="item.isActive ? 'nav-link active' : 'nav-link' "
          :data-name="item.name"
        >
          <div
            v-if="item.name === 'console_chat' && hasActiveConversation"
            class="nav-link-notification-dot">
          </div>
          <div class="nav-link-inner">
            <md-icon :name="item.icon" classes="icon" v-if="item.icon" />
            <img :src="item.img" class="icon" v-else-if="item.img" />
          </div>
          <div class="nav-link-tooltip-wrapper">
            <span class="nav-link-tooltip">{{ t(item.label) }}</span>
          </div>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { useObservable } from '@vueuse/rxjs'
import { useI18n } from 'vue-i18n'
import { liveQuery } from 'dexie'
import { computed, onMounted, ref, reactive } from 'vue'

import { fetchUser, fetchCustomer, fetchSkills, setAuthToken } from './api'
import { NavButton } from '@/types/button'
import MdIcon from '@shared/components/MdIcon.vue'
import { db } from '@shared/db/db'
import { Customer } from '@shared/types/customer'
import { Token } from '@shared/types/token'
import { User } from '@shared/types/user'
import logo from '@shared/assets/img/logo-gb-t.png'

// data
const { t } = useI18n({})
const buttons = reactive<Array<NavButton>>([{
  img: logo,
  isActive: false,
  label: 'menu.home',
  name: 'home',
  path: '/',
  permissions: []
}, {
  icon: 'home',
  isActive: false,
  label: 'menu.dashboard',
  name: 'dashboard',
  path: '/dashboard',
  permissions: ['dashboard']
}, {
  icon: 'forum',
  isActive: false,
  label: 'menu.console',
  name: 'console_chat',
  path: '/gb-agent',
  permissions: ['console_chat', 'comments']
}, {
  icon: 'add_comment',
  isActive: false,
  label: 'menu.messaging',
  name: 'messaging',
  path: '/messaging',
  permissions: ['bot', 'social_networks', 'comments']
}, {
  icon: 'post_add',
  isActive: false,
  label: 'menu.third-parties',
  name: 'third-parties',
  path: '/third-parties',
  permissions: ['third-parties']
}, {
  icon: 'widgets',
  isActive: false,
  label: 'menu.botgroups',
  name: 'botgroups',
  path: '/botgroups',
  permissions: ['integration']
}, {
  icon: 'view_list',
  isActive: false,
  label: 'menu.webivr',
  name: 'webivr',
  path: '/webivr',
  permissions: ['webivr']
}, {
  icon: 'psychology',
  isActive: false,
  label: 'menu.ai',
  name: 'dialogflow',
  path: '/dialogflow',
  permissions: ['dialogflow']
}, {
  icon: 'send',
  isActive: false,
  label: 'menu.campaigns',
  name: 'campaign',
  path: '/campaigns',
  permissions: ['campaigns']
}, {
  icon: 'poll',
  isActive: false,
  label: 'menu.stats',
  name: 'stats',
  path: '/stats',
  permissions: ['statistics', 'reports-dispatcher']
}, {
  icon: 'settings',
  isActive: false,
  label: 'menu.settings',
  name: 'settings',
  path: '/settings',
  permissions: [
    'my_account',
    'manage_user',
    'add_user',
    'services_configuration',
    'extra_services',
    'bot_builder',
    'groups',
    'add_group',
    'edit_group'
  ]
}])
const customer = ref<Customer | null>(null)
const dbIsInit = ref(false)
const hasActiveConversation = ref(false)
const token = ref<Token | null>(null)
const user = ref<User | null>(null)

// computed
const activeButtons = computed(() => {
  const activeButtons: Array<NavButton> = []
  buttons.forEach((button: NavButton) => {
    if (button.permissions.length === 0 ||
        (user.value && user.value.permissions.features.some((item) => {
          return button.permissions.includes(item)
        }))) {
      button.isActive = Boolean(window.location.pathname.indexOf(button.path) > -1)
      activeButtons.push(button)
    }
  })
  if (activeButtons.length > 1 && activeButtons[0].name === 'home' &&
      customer.value && customer.value.logo) {
    activeButtons[0].img = customer.value.logo
  }
  return activeButtons
})

const isReady = computed(() => {
  return (token.value && dbIsInit.value)
})

// functions
const getOrCreate = async (
  srvUser: Record<string, any>,
  srvCustomer: Record<string, any>,
  srvSkills: Record<string, any>): void => {
  let user_: User | undefined = await db.user.get({ id: srvUser.id })
  if (user_ === undefined) {
    await db.user.add(srvUser)
    user_ = await db.user.get({ id: srvUser.id })
  } else {
    await db.user.update(user_.id, srvUser)
  }
  if (user_ !== undefined) {
    user.value = user_
  }
  let customer_: Customer | undefined = await db.customer.get({ id: srvCustomer.id })
  if (customer_ === undefined) {
    await db.customer.add(srvCustomer)
    customer_ = await db.user.get({ id: srvCustomer.id })
  } else {
    await db.customer.update(user.value.id, srvCustomer)
  }
  if (customer_ !== undefined) {
    customer.value = customer_
  }
}

const navigate = (path: string): void => {
  buttons.forEach((item: NavButton) => {
    item.isActive = false
    if (path === item.path) {
      item.isActive = true
    }
  })
  window.history.pushState({}, 'CMS-Micro', path)
}

const removeLoader = (): void => {
  const loader: HTMLDivElement | null = document.querySelector('#gb-webivr-loader')
  if (loader) {
    const speed = 2000
    const seconds = speed / 1000
    loader.style.transition = `opacity ${seconds}s ease`
    loader.style.opacity = '0'
    window.setTimeout(() => {
      loader.remove()
    }, speed)
  }
}

onMounted(() => {
  useObservable(
    liveQuery(() => db.token.toCollection().first((data: any) => {
      token.value = data ? data.token : null
      if (token.value) {
        setAuthToken(token.value)
        document.querySelector('main.main')?.classList.add('auth')
        Promise.all([
          fetchUser(),
          fetchCustomer(),
          fetchSkills()
        ]).then(async ([srvUser, srvCustomer, srvSkills]: any) => {
          await getOrCreate(srvUser, srvCustomer, srvSkills)
          dbIsInit.value = true
          removeLoader()
        }).catch((err: Record<string, string>) => {
          if (err.error === 'unauthorized') {
            window.location.ref = '/logout'
          }
        })
      } else {
        removeLoader()
      }
    }))
  )
  document.body.addEventListener(
    'gb:cms:gba:rooms:events:unread-msgs',
    (e) => {
      hasActiveConversation.value = e.detail.status
    },
    false
  )
})
</script>

<style lang="scss" scoped>
@import "@shared/assets/scss/global.scss";

nav {
  background-color: $primary;
  border-radius: 20px;
  box-shadow: 20px 0 180px 0 rgba($color: $content, $alpha: .15);
  display: flex;
  flex-shrink: 0;
  height: calc(100vh - 20px);
  margin: 10px 0 10px 10px;
  position: relative;
  z-index: 10;

  @media (max-width: $break-large) {
    border-radius: 0;
    display: block;
    height: 60px;
    margin: 0;
    overflow-x: auto;

    &.hidden {
      display: none;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;

    @media (max-width: $break-large) {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .nav-link-wrapper {
      flex-grow: 1;
      margin: 8px 17px;
      max-height: 65px;

      @media (max-width: $break-large) {
        flex-grow: 0;
      }

      &:first-child {
        flex-grow: 3.5;
        margin-top: 23px;
        max-height: 115px;

        @media (max-width: $break-large) {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      button.nav-link {
        align-items: flex-end;
        appearance: none;
        background: none;
        border: none;
        display: flex;
        flex-direction: column;
        height: 45px;
        outline: none;
        padding: 0;
        width: 45px;

        &:hover {
          transform: scale(1.1);
          transition: transform .5s;
        }

        .nav-link-inner {
          & .icon {
            align-items: center;
            border-radius: 16px;
            background-color: #347DF5; // rgba($color: white, $alpha: .2);
            color: #fff;
            font-size: 20px;
            display: flex;
            flex-shrink: 0;
            height: 45px;
            justify-content: center;
            position: relative;
            transition: all .25s;
            top: 0px;
            width: 45px;
          }
        }

        .nav-link-notification-dot {
          background-color: $secondary-light;
          border-radius: 10px;
          display: block;
          position: absolute;
          height: 12px;
          width: 12px;
          z-index: 200;
        }

        .nav-link-tooltip-wrapper {
          display: flex;
          height: 45px;
          flex-direction: column;
          justify-content: center;
          left: calc(100% + 30px);
          opacity: 0;
          pointer-events: none;
          position: absolute;
          transition: opacity .5s, transform .5s, background-color .25s;
          transition-delay: 0.5;

          .nav-link-tooltip {
            background-color: $primary;
            border-radius: 5px;
            color: #fff;
            font-weight: bold;
            font-family: Poppins;
            font-size: .75rem;
            padding: 10px 20px;
            text-transform: uppercase;
            white-space: nowrap;
          }

          @media (max-width: $break-large) {
            display: none;
          }
        }

        &:hover .nav-link-tooltip-wrapper {
          opacity: 1;
          transition: opacity .5s, transform .5s;
        }

        &.active .nav-link-inner .icon {
          background-color: white;
          box-shadow: 0 0 40px $primary-fade;
          color: $primary;
        }
      }
    }
  }
}
</style>

import { h, createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import singleSpaVue from 'single-spa-vue'

import App from './App.vue'
import { messages, defaultLocale } from '@/i18n'

const i18n = createI18n({
  messages,
  legacy: false,
  globalInjection: true,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
})

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render () {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      })
    }
  },
  handleInstance: (app) => {
    app.use(i18n)
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount

import axios, { AxiosError, AxiosResponse } from 'axios'

const _http = (
  method: string,
  url: string,
  data?: Record<string, string> | undefined
): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: data
    })
      .then((response: AxiosResponse) => {
        resolve(response.data)
      })
      .catch((error: AxiosError) => {
        reject(error?.response?.data)
      })
  })
}

export default function setAuthToken (token: string) {
  axios.defaults.headers.common.Authorization = ''
  delete axios.defaults.headers.common.Authorization

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

const fetchCustomer = (): Promise<any> => {
  return _http('get', `${process.env.VUE_APP_BACKEND_URL}/customer/`)
}

const fetchSkills = (): Promise<any> => {
  return _http('get', `${process.env.VUE_APP_BACKEND_URL}/skills/`)
}

const fetchUser = (): Promise<any> => {
  return _http('get', `${process.env.VUE_APP_BACKEND_URL}/user/account`)
}

export {
  fetchCustomer,
  fetchSkills,
  fetchUser,
  setAuthToken
}

<template>
  <span :class="clss">{{ name }}</span>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  classes?: string
  name?: string
  kind?: string
}>()

const clss = computed((): string => {
  let classes = 'material-icons'
  if (props.classes) {
    classes += ' ' + props.classes
  }
  if (props.kind && props.kind !==  'regular') {
    classes += ' ' + props.kind
  }
  return classes
})
</script>

<style lang="scss">
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Icons Outline';
  font-style: normal;
  font-weight: 400;
  src: url('~@shared/assets/fonts/MaterialIcons-Outline.woff2') format('woff');
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;  /* Preferred icon size */
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-transform: none;
  top: 3px;
  white-space: nowrap;
  word-wrap: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.outline {
    font-family: 'Material Icons Outline';
  }
}

// .pop-enter-active {
//   animation: pop-in .5s;
// }
// .pop-leave-active {
//   animation: pop-in .5s reverse;
// }
/* @keyframes pop-in {
  0% {
    transform: translate(-53%, 0) scale(0);
  }
  50% {
    transform: translate(-53%, 0) scale(1.5);
  }
  100% {
    transform: translate(-53%, 0) scale(1);
  }*/
// }
</style>

import Dexie, { Table } from 'dexie'

import { Customer, Service } from '@shared/db/types/customer'
import { Group } from '@shared/db/types/group'
import { Token, User } from '@shared/db/types/user'

export class GbChayallCms extends Dexie {
  customer!: Table<Customer>
  token!: Table<Token>
  user!: Table<User>

  constructor () {
    super('gb:chayall:cms')
    this.version(1).stores({
      customer: '_id++, id, name, logo, offer_desc, over_quota, on_trial, groups, stripe_customer, services, extra_services, extra_data, attachment_preview',
      token: '_id++, token',
      user: '_id++, id, first_name, last_name, email, photo, user_role, phone_number, timezone, has_confirm_email, date_joined, language, permissions, max_rooms, max_reviews, notif_new_msg, notif_late_msg, notif_new_msg_sound'
    })

    this.customer = this.table("customer")
    this.token = this.table("token")
    this.user = this.table("user")

  }
}

export const db = new GbChayallCms()

